var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c192d537d390574fd8ca799b962c0456c82ceb4d"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

export const configSentry = () => {
  if (validateConfig()) {
    Sentry.init({
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
      release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
      tracesSampleRate: Number(process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE),
      tracePropagationTargets: [
        "localhost",
        `^${process.env.NEXT_PUBLIC_BACKEND_URL}/api`, // regex to match backend for distributed tracing
        `^${process.env.NEXT_PUBLIC_HAVEN_URL}`, // regex to match haven for distributed tracing
      ],
    });
  }
};

// validateConfig returns true if we have enough to initialize Sentry
// and throws warnings if we are missing configs in staging/prod
const validateConfig = () => {
  if (
    !["staging", "production"].includes(
      process.env.NEXT_PUBLIC_SENTRY_ENV as string,
    )
  ) {
    return false;
  }

  let valid = true;
  if (!process.env.NEXT_PUBLIC_SENTRY_DSN) {
    console.warn(
      "Sentry DSN not found! Please set NEXT_PUBLIC_SENTRY_DSN in your build process and environment.",
    );
    valid = false;
  }

  if (!process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE) {
    console.warn(
      "Sentry sample rate not found! Please set NEXT_PUBLIC_SENTRY_SAMPLE_RATE in your build process and environment.",
    );
    valid = false;
  }

  if (!process.env.NEXT_PUBLIC_SENTRY_ENV) {
    console.warn(
      "Sentry environment not found! Please set NEXT_PUBLIC_SENTRY_ENV in your build process and environment.",
    );
  }

  if (!process.env.NEXT_PUBLIC_SENTRY_RELEASE) {
    console.warn(
      "Sentry release not found! Please set NEXT_PUBLIC_SENTRY_RELEASE in your build process and environment.",
    );
  }

  return valid;
};

configSentry();
