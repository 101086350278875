"use client";

import React, { useCallback, useContext, useEffect, useState } from "react";

import { v4 } from "uuid";

import { event_type } from "@/hl-common/types/api/PrismaEnums";
import type { SkipCondition } from "@/hl-common/types/api/components/automations/SkipCondition";
import type { CardEntity } from "@/hl-common/types/api/entities/Cards";
import type {
  EventEntity,
  IngestEventRequest,
} from "@/hl-common/types/api/entities/Events";
import type { ModuleEntity } from "@/hl-common/types/api/entities/Modules";
import { CourseContext } from "@/utils/CourseContext";
import { EventContext } from "@/utils/EventContext";
import { getCompletedCardsByModule, getModule } from "@/utils/api/client";
import { getErrorMessage } from "@/utils/api/fetch";
import { useParams, useRouter } from "next/navigation";
import { detectAndHandleRushing, getLockout } from "./rushing";

export enum DisplayMode {
  CARD = "card",
  PRE_COMPLETE = "pre_complete",
  MODULE_COMPLETE = "module_complete",
  COURSE_COMPLETE = "course_complete",
  RUSHING = "rushing",
}

export type CardSubmitFunc = (
  answer: any,
  correct: boolean,
  skip: boolean,
  retryable: boolean,
) => void;

const emptyCardSubmit: CardSubmitFunc = (
  answer,
  correct,
  skip,
  retryable,
) => {};

export type NextCard = () => void;
const emptyNextCard: NextCard = () => {};

export const ModuleContext = React.createContext({
  module: null as ModuleEntity | null,
  moduleError: "",
  moduleLoading: true,
  moduleProgress: 0,
  card: null as CardEntity | null,
  displayMode: DisplayMode.CARD,
  justFinishedModuleId: 0,
  loadModule: async (moduleId: number) => {},
  nextCard: emptyNextCard,
  handleCardSubmit: emptyCardSubmit,
  showCard: () => {},
  showCompleteScreen: (
    screen: DisplayMode.MODULE_COMPLETE | DisplayMode.COURSE_COMPLETE,
  ) => {},
});

export const WithModule = ({ children }: { children: React.ReactNode }) => {
  const { replace } = useRouter();
  const { course, courseEvents, addCourseEvent } = useContext(CourseContext);
  const { course_id } = useParams<{ course_id: string }>();
  const courseId = Number.parseInt(course_id);

  const { addEvent } = useContext(EventContext);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [module, setModule] = useState(null as ModuleEntity | null);
  const [completedCardEvents, setCompletedCardEvents] = useState(
    [] as IngestEventRequest[],
  );

  // The card index is the index of the active card. It can point one past the
  // end of the last card, when we're at the end of a module.
  const [cardIndex, setCardIndex] = useState(0);

  // The display mode, together with the card index, controls what is being
  // shown. Display modes other than CARD are used for transitions, like the
  // module complete screen. Conceptually, the RUSHING interstitial is shown
  // before a card, and the end screens are shown after the last card.
  const [displayMode, setDisplayMode] = useState(DisplayMode.CARD);

  // Computed parts of the state: card, cardStart, moduleProgress etc. These update
  // whenever the card index and/or display mode change.
  const card: CardEntity | null = module?.cards?.[cardIndex] ?? null;
  const [cardStart, setCardStart] = useState(new Date().toISOString()); // ISO timestamp
  const [moduleProgress, setModuleProgress] = useState(0);

  // this value is set when advancing to the pre-complete screen.
  // we use it to hold onto the id of the module that just finished
  // while the next-recommended-module is loaded underneath it.
  const [justFinishedModuleId, setJustFinishedModuleId] = useState(0);

  // on cardIndex or displayMode change....
  // biome-ignore lint/correctness/useExhaustiveDependencies: we want to depend on cardIndex, even though the actual value isn't used in the effect.
  useEffect(() => {
    // scroll to top of page
    window.scrollTo(0, 0);
  }, [cardIndex, displayMode]);

  // called from the module loader component
  const loadModule = useCallback(
    async (moduleId: number) => {
      setLoading(true);
      setError("");

      try {
        const modulePromise = getModule({ params: { courseId, moduleId } });
        const completedCardsPromise = getCompletedCardsByModule({
          params: { courseId, moduleId },
        });
        const moduleData = (await modulePromise).data;
        const cards = moduleData.cards ?? [];

        if (cards.length === 0) {
          throw new Error("The module is empty.");
        }

        const completedCards = (await completedCardsPromise).data;

        // Compute the initial card index. Normally, we resume a module at the first uncompleted card.
        let idx = startingIndex(cards, completedCards);
        if (idx >= cards.length) {
          // The user has already completed this module.
          if (moduleData.repeatable) {
            // For repeatable modules, restart at the beginning.
            idx = 0;
          } else {
            // Since the module is not repeatable, go back to the course overview.
            replace(courseId ? `/courses/${courseId}` : "/courses");
          }
        }

        setModule(moduleData);
        setCompletedCardEvents(completedCards);
        setCardIndex(idx);

        if (displayMode === DisplayMode.CARD) {
          // If we're showing a card (e.g., on initial load of a module, as
          // opposed to at the end of a previous module), also initialize the
          // rest of the state (cardStart, moduleProgress).
          setCardStart(new Date().toISOString());
          setModuleProgress(idx / cards.length);

          // If the user was rushing, we should display the interstitial even
          // after reloading the page.
          if (isRushing()) {
            setDisplayMode(DisplayMode.RUSHING);
          }
        }
      } catch (error) {
        setError(getErrorMessage(error));
        setModule(null);
        setCompletedCardEvents([]);
        setCardIndex(0);
      }

      setLoading(false);
    },
    [replace, courseId, displayMode],
  );

  // Advance the index, and show the completion screen if we just completed the module.
  const nextCard = useCallback(() => {
    if (!module?.cards || !module.id) {
      return;
    }

    const newIndex = cardIndex + 1;

    setCardIndex(newIndex);
    setCardStart(new Date().toISOString());
    setModuleProgress(newIndex / module.cards.length);

    if (newIndex === module.cards.length) {
      // End of module or course!
      // Show the pre-complete screen while answers are syncing.
      setDisplayMode(DisplayMode.PRE_COMPLETE);

      // we snapshot the module here for use in pre_complete and module_complete screens...
      setJustFinishedModuleId(module.id);
    } else if (isRushing()) {
      setDisplayMode(DisplayMode.RUSHING);
    }
  }, [cardIndex, module]);

  // Start showing the current card. This is used to resume learning after the end screens.
  const showCard = useCallback(() => {
    if (!module?.cards) {
      return;
    }

    setDisplayMode(DisplayMode.CARD);
    setCardStart(new Date().toISOString());
    setModuleProgress(cardIndex / module.cards.length);
  }, [cardIndex, module?.cards]);

  // Show the requested completion screen.
  const showCompleteScreen = useCallback(
    (screen: DisplayMode.MODULE_COMPLETE | DisplayMode.COURSE_COMPLETE) => {
      setDisplayMode(screen);
    },
    [],
  );

  const wasCourseJustCompleted = useCallback(
    (moduleId: number) => {
      if (!course?.modules) {
        return false;
      }

      const previouslyCompletedModules = new Set(
        courseEvents
          .filter((e) => e.type === event_type.module_complete)
          .map((e) => e.moduleId),
      );

      const incompleteRequiredModules = course.modules.filter(
        (m) => !m.optional && !previouslyCompletedModules.has(m.id),
      );

      return (
        incompleteRequiredModules.length === 1 &&
        incompleteRequiredModules[0].id === moduleId
      );
    },
    [courseEvents, course],
  );

  const handleCardSubmit = useCallback(
    (answer: any, correct: boolean, skip: boolean, retryable: boolean) => {
      if (!course || !module?.cards?.length || !card) {
        return;
      }

      const cardEnd = new Date();
      const duration = cardEnd.getTime() - new Date(cardStart).getTime();
      const cardCompleted = correct || skip || !retryable;

      // extra events
      const moduleBegin = cardIndex === 0;
      const moduleComplete =
        cardIndex === module.cards.length - 1 && cardCompleted;
      const courseComplete =
        moduleComplete && wasCourseJustCompleted(module.id);

      // Detect rushing and trigger lockouts. Store another extra event if a
      // lockout happens.
      const rushingLockout =
        cardCompleted &&
        detectAndHandleRushing(duration, card, course, completedCardEvents);

      const event: IngestEventRequest = {
        uuid: v4(),
        courseId: course?.id,
        moduleId: module.id,
        cardId: card.id,
        type: event_type.card_submit,
        answer,
        correct,
        skip,
        retryable,
        timestamp: cardEnd.toISOString(),
        duration,
        extraEvents: {
          moduleBeginUuid: moduleBegin ? v4() : undefined,
          moduleCompleteUuid: moduleComplete ? v4() : undefined,
          courseCompleteUuid: courseComplete ? v4() : undefined,
          rushingLockoutUuid: rushingLockout ? v4() : undefined,
        },
      };

      // send to the server
      addEvent(event);

      // save to CourseContext
      addCourseEvent(event);

      if (cardCompleted) {
        // save to completedCardEvents
        setCompletedCardEvents([...completedCardEvents, event]);
      }
    },
    [
      addEvent,
      addCourseEvent,
      course,
      wasCourseJustCompleted,
      module,
      cardIndex,
      card,
      completedCardEvents,
      cardStart,
    ],
  );

  const isRushing = useCallback(() => {
    return getLockout().end > Date.now();
  }, []);

  // Skip the current card, if it has a satisfied skip condition.
  // This happens only during CARD display mode, because we do not want to disturb the module end screens
  useEffect(() => {
    if (card?.automation?.skipCondition && displayMode === DisplayMode.CARD) {
      if (shouldSkip(card.automation.skipCondition, completedCardEvents)) {
        handleCardSubmit(null, false, true, false);
        nextCard();
      }
    }
  }, [card, completedCardEvents, displayMode, handleCardSubmit, nextCard]);

  return (
    <ModuleContext.Provider
      value={{
        loadModule,
        module,
        moduleError: error,
        moduleLoading: loading,
        moduleProgress,
        card,
        displayMode,
        justFinishedModuleId,
        nextCard,
        handleCardSubmit,
        showCard,
        showCompleteScreen,
      }}
    >
      {children}
    </ModuleContext.Provider>
  );
};

// find the first card that hasn't been answered correctly, hasn't been skipped, or wasn't retryable
const startingIndex = (cards: CardEntity[], events: EventEntity[]) => {
  if (events.length === 0) {
    return 0;
  }

  const idx = cards.findIndex((card) => {
    return !events.find((event) => {
      return (
        event.cardId === card.id &&
        (event.correct || event.skip || !event.retryable)
      );
    });
  });
  if (idx === -1) {
    return cards.length; // all cards answered correctly
  }

  return idx;
};

// check if the skip condition is met by the completedCardEvents
// TODO: add unit tests
const shouldSkip = (
  skipCondition: SkipCondition,
  completedCardEvents?: IngestEventRequest[],
) => {
  if (!skipCondition.card?.id) {
    return false;
  }

  if (!completedCardEvents || completedCardEvents.length === 0) {
    return false;
  }

  const relevantEvent = completedCardEvents.find(
    (event) => skipCondition.card && event.cardId === skipCondition.card.id,
  );

  if (!relevantEvent) {
    return false;
  }

  // detect if the previous card was skipped
  if (skipCondition.test === "wasSkipped") {
    return !!relevantEvent.skip; // true if skipped, false otherwise
  }

  // checkbox
  if (relevantEvent.answer?.checkboxIDs) {
    switch (skipCondition.test) {
      case "includes":
        return relevantEvent.answer.checkboxIDs.includes(
          skipCondition.answerID,
        );
      case "excludes":
        return !relevantEvent.answer.checkboxIDs.includes(
          skipCondition.answerID,
        );
      case "equals":
        return (
          relevantEvent.answer.checkboxIDs.length === 1 &&
          relevantEvent.answer.checkboxIDs[0] === skipCondition.answerID
        );
      default:
        return false;
    }
  }

  // radio
  if (relevantEvent.answer?.radioID) {
    switch (skipCondition.test) {
      case "includes":
      case "equals":
        return relevantEvent.answer.radioID === skipCondition.answerID;
      case "excludes":
        return relevantEvent.answer.radioID !== skipCondition.answerID;
      default:
        return false;
    }
  }

  return false;
};
